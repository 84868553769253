import * as React from "react";

import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { about, content, contentFlex, image, paragraph } from './valpar.module.scss';

import BottomSpace from "../components/bottomSpace";

import Zoom from '../components/Zoom';

const IndexPage = () => {
    return (
        <Layout pageTitle="Valpar" largeHero={true}>
            <div className={content}>
                <div className={contentFlex}>
                    <div className={about}>
                        <h1>Valpplaner</h1>
                        {/*                         <p><i>
                            Lady och Pascal har fått valpar - hanar letar hem. Kontakta oss för mer information. 🐾❤️
                        </i></p> */}

                        <p><i>
                            Vi planerar valpar efter Lady under 2025, kontakta för mer information 🐾❤️ Se mer information om Lady nedan och under "Hundar", Lady är till vänster i bilden.
                        </i></p>

                        <p className={paragraph}>
                            <h3>Lady</h3>

                            u. Amore Vincit Omnia Fire And Blood
                            <br />
                            Dvärgpudel, aprikosröd, 35 cm
                            <br />
                            <br />
                            e: Engine Xander (multichampion)
                            <br />
                            u: Sagotassens Beskow
                            <br />
                            <br />
                            Ögon: Distichiasis
                            <br />
                            patella, ua
                            <br />
                            PRCD-pra: A (fri)
                            <br />
                            <br />
                            Fulltandad, korrekt bett
                        </p>
                    </div>

                    <Zoom wrapperClass={image}>
                        <StaticImage loading="eager" src={'../images/lady_pascal.png'} alt={"Lady och Pascal"} />
                    </Zoom>
                </div>

            </div>

            <BottomSpace />
        </Layout>
    )
}

export default IndexPage